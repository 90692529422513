import * as React from "react";

const SVGRecruitment = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M59.5 57C59.656 57 59.8075 57.012 59.9575 57.0345C58.8875 57.7971 58.0154 58.8045 57.4139 59.9727C56.8124 61.1409 56.4991 62.436 56.5 63.75C56.5 64.986 56.7715 66.1605 57.259 67.212C56.2684 67.4099 55.2602 67.5064 54.25 67.5C49.132 67.5 46 65.331 46 62.25V60C46 59.2044 46.3161 58.4413 46.8787 57.8787C47.4413 57.3161 48.2044 57 49 57H59.5Z"
			fill="black"
		/>
		<path
			d="M68.5 51.75C68.5 52.7446 68.1049 53.6984 67.4017 54.4016C66.6984 55.1049 65.7446 55.5 64.75 55.5C63.7554 55.5 62.8016 55.1049 62.0983 54.4016C61.3951 53.6984 61 52.7446 61 51.75C61 50.7554 61.3951 49.8016 62.0983 49.0983C62.8016 48.3951 63.7554 48 64.75 48C65.7446 48 66.6984 48.3951 67.4017 49.0983C68.1049 49.8016 68.5 50.7554 68.5 51.75Z"
			fill="black"
		/>
		<path
			d="M54.25 45C54.9394 45 55.6221 45.1358 56.2591 45.3996C56.896 45.6635 57.4748 46.0502 57.9623 46.5377C58.4498 47.0252 58.8365 47.604 59.1004 48.2409C59.3642 48.8779 59.5 49.5606 59.5 50.25C59.5 50.9394 59.3642 51.6221 59.1004 52.2591C58.8365 52.896 58.4498 53.4748 57.9623 53.9623C57.4748 54.4498 56.896 54.8365 56.2591 55.1004C55.6221 55.3642 54.9394 55.5 54.25 55.5C52.8576 55.5 51.5223 54.9469 50.5377 53.9623C49.5531 52.9777 49 51.6424 49 50.25C49 48.8576 49.5531 47.5223 50.5377 46.5377C51.5223 45.5531 52.8576 45 54.25 45Z"
			fill="black"
		/>
		<path
			d="M71.5 63.75C71.5 65.5402 70.7888 67.2571 69.523 68.523C68.2571 69.7888 66.5402 70.5 64.75 70.5C62.9598 70.5 61.2429 69.7888 59.977 68.523C58.7112 67.2571 58 65.5402 58 63.75C58 61.9598 58.7112 60.2429 59.977 58.977C61.2429 57.7112 62.9598 57 64.75 57C66.5402 57 68.2571 57.7112 69.523 58.977C70.7888 60.2429 71.5 61.9598 71.5 63.75ZM65.5 60.75C65.5 60.5511 65.421 60.3603 65.2803 60.2197C65.1397 60.079 64.9489 60 64.75 60C64.5511 60 64.3603 60.079 64.2197 60.2197C64.079 60.3603 64 60.5511 64 60.75V63H61.75C61.5511 63 61.3603 63.079 61.2197 63.2197C61.079 63.3603 61 63.5511 61 63.75C61 63.9489 61.079 64.1397 61.2197 64.2803C61.3603 64.421 61.5511 64.5 61.75 64.5H64V66.75C64 66.9489 64.079 67.1397 64.2197 67.2803C64.3603 67.421 64.5511 67.5 64.75 67.5C64.9489 67.5 65.1397 67.421 65.2803 67.2803C65.421 67.1397 65.5 66.9489 65.5 66.75V64.5H67.75C67.9489 64.5 68.1397 64.421 68.2803 64.2803C68.421 64.1397 68.5 63.9489 68.5 63.75C68.5 63.5511 68.421 63.3603 68.2803 63.2197C68.1397 63.079 67.9489 63 67.75 63H65.5V60.75Z"
			fill="black"
		/>
	</svg>
);

export default SVGRecruitment;
