import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "react-bootstrap/Button";
import SVGDottedPattern from "./SVG/dotted-pattern";
import SVGPolygon from "./SVG/polygon";

const urlTargetBehaviour = (url) => {
	if (url.includes("http")) {
		return "_blank";
	} else {
		return "_self";
	}
}

const PhotoRight = ({
	heading,
	text,
	img,
	cta,
	ctaUrl,
	height,
	imgAlt,
	width,
	bColour,
	fw,
	mobileOff,
	secondaryCta,
	secondaryCtaUrl
}) => {
	const newHeight = height || "30rem";
	const newWidth = width || "30rem";
	return (
		<section className={`my-lg-6 ${mobileOff ? "pt-3" : ""} `}>
			<Container>
				<Row className="align-items-center">
					{mobileOff !== true && (
						<Col xs={12} className="d-xl-none text-center">
							<GatsbyImage
								className={`mb-4 mx-auto ${fw ? "w-100" : ""} `}
								alt={imgAlt}
								image={img}
							/>
						</Col>
					)}
					<Col
						className="pe-xl-5 text-md-center text-lg-start pt-md-3 pt-lg-5 pt-xl-0"
						xs={12}
						xl={6}
					>
						<div className="position-relative">
							<SVGPolygon
								className="position-absolute d-none d-lg-block"
								style={{ zIndex: -1, left: "-1rem", top: "-2.5rem" }}
							/>
							<h2 className=" text-start  mb-3">{heading}</h2>
						</div>
						<hr className="hr-primary" />

						<p className="text-start mb-4">{text}</p>
						<Button
							variant="secondary"
							className={` w-100 w-md-auto cta-btn btn-black fs-5 pb-2 mt-4 mt-lg-0 ${
								cta ? "" : "d-none"
							}`}
							as={Link}
							to={ctaUrl}
						>
							{cta}
						</Button>
						{ (secondaryCta && secondaryCtaUrl) && (
							<Button
								variant="primary"
								className={` mx-md-2 mx-lg-2 w-100 w-md-auto cta-btn btn-black fs-5 pb-2 mt-4 mt-lg-0 ${
									secondaryCta ? "" : "d-none"
								}`}
								as={Link}
								href={secondaryCtaUrl}
								target={urlTargetBehaviour(secondaryCtaUrl)}
							>
								{secondaryCta}
							</Button>
						)}
					</Col>
					<Col xs={12} xl={6} className="mb-4 mb-md-0 d-xl-block d-none">
						<div
							className="position-relative mx-auto "
							style={{ height: newHeight, width: newWidth }}
						>
							<GatsbyImage
								className="position-absolute"
								style={{ height: newHeight, width: newWidth }}
								alt={imgAlt}
								image={img}
							/>{" "}
							<SVGDottedPattern
								style={{ right: "-3rem", bottom: "-3.2rem", zIndex: "-2" }}
								className="position-absolute d-none d-lg-block "
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PhotoRight;
