import * as React from "react";

const SVGDocumentation = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M64.7 47.3L57.7 40.3C57.5 40.1 57.3 40 57 40H47C45.9 40 45 40.9 45 42V66C45 67.1 45.9 68 47 68H63C64.1 68 65 67.1 65 66V48C65 47.7 64.9 47.5 64.7 47.3ZM57 42.4L62.6 48H57V42.4ZM63 66H47V42H55V48C55 49.1 55.9 50 57 50H63V66Z"
			fill="black"
		/>
		<path d="M49 60H61V62H49V60Z" fill="black" />
		<path d="M49 54H61V56H49V54Z" fill="black" />
	</svg>
);

export default SVGDocumentation;
