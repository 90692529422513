import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGStar from "./SVG/star";
import SVGStarNormal from "./SVG/star-normal";
import Link from "./link";

const ReviewCard = ({ review, yellow, onSetReadMore, readMore, noShadow }) => {
	const bg = yellow ? (noShadow ? "bg-primary" : "bg-white") : "bg-primary";

	return (
		<Card
			className={`border-0 ${bg} review-card ${
				noShadow ? "no-shadow" : "feature-box"
			} my-4 mx-lg-4 px-4 rounded flex-grow-0`}
			style={{
				height: review.title === readMore?.title ? "auto" : "26rem",
			}}
		>
			<Row>
				<Col
					className={` d-flex justify-content-center align-items-center 
						}`}
				>
					<div
						className="bg-white mt-4 d-flex align-items-center justify-content-center"
						style={{ width: "12rem", height: "12rem", borderRadius: "100%" }}
					>
						<GatsbyImage
							style={{ maxWidth: "9rem" }}
							Tag="section"
							className="hero-services"
							image={
								review.Reviewinfo.image.localFile.childImageSharp
									.gatsbyImageData
							}
							alt={review.Reviewinfo.image.altText}
						></GatsbyImage>
					</div>
				</Col>
			</Row>
			<Card.Header className={`${bg}  border-0`}>
				<Row className="align-items-center">
					<Col xs={9}>
						<Row className="align-items-center ">
							<Col className="d-flex align-items-center">
								<p className="d-inline-block pb-0 mb-0 fw-regular">
									{review.Reviewinfo.rating}
								</p>
								{yellow !== true && (
									<SVGStarNormal
										className="d-inline-block ms-1"
										style={{ width: "15px", height: "15px" }}
									/>
								)}
								{yellow === true && (
									<SVGStar
										className="d-inline-block ms-1"
										style={{ width: "15px", height: "15px" }}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Card.Title className="mb-2">{review.Reviewinfo.name}</Card.Title>
					<Card.Text>
						<p
							className={`${
								review.title === readMore?.title ? "" : "paragraph-clip-3"
							} overflow-hidden`}
						>
							<span className="">"</span>
							<span>{review.Reviewinfo.review}</span>
							<span className="">"</span>
						</p>
					</Card.Text>
				</Row>
				<Row>
					<Col className="text-start">
						{review.Reviewinfo.review.length > 100 && (
							<Link
								role="button"
								onClick={() => onSetReadMore(review.title)}
								className={`${
									yellow ? "link-white" : "link-orange"
								} text-decoration-none fw-bold pe-auto`}
							>
								{review.title === readMore?.title ? "Read Less" : "Read More"}
							</Link>
						)}
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ReviewCard;
