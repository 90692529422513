import React from "react";
import Link from "./link";
import Col from "react-bootstrap/Col";

const ServiceCard = ({ header, text, icon, url, linkText }) => {
	return (
		<Col className="mb-7 gx-5  " xl={4} md={6}>
			<div className="text-center feature-box h-100 position-relative bg-white px-4 pb-4 pt-5">
				<div className="text-center position-absolute start-50 top-0 translate-middle ">
					{icon}
				</div>
				<h4
					style={{ fontWeight: "600" }}
					className="font-normal text-uppercase mb-4"
				>
					{header}
				</h4>
				<p className="">{text}</p>
				<Link
					className={` link-secondary cta-link fw-normal mt-3 ${
						linkText ? "" : "d-none"
					}`}
					to={url}
				>
					{linkText}
				</Link>
			</div>
		</Col>
	);
};

export default ServiceCard;
