import React from "react";
import Link from "./link";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import Button from "react-bootstrap/Button";
import SVGHh from "./SVG/home-hero";
import Navigation from "./navigation";
import SSRProvider from "react-bootstrap/SSRProvider";
import SVGStar from "./SVG/star";
import SVGStarNormal from "./SVG/star-normal";

const Hero = ({ title, description, img, imgAlt, review, badge }) => {
	const image = getImage(img);
	return (
		<SSRProvider>
			<div className="position-relative bg-smallscreen home-hero-div ">
				<Navigation background="none" />
				<Container className="position-relative w-100 ">
					<SVGHh
						style={{
							zIndex: -3,
							right: "-10px",
							height: "98vh",
							top: "-150px",
						}}
						className="position-absolute d-none d-xl-block vw-100  "
					/>
				</Container>
				<Container
					style={{ zIndex: 1 }}
					className="pt-3 text-secondary position-relative"
				>
					<Row className=" pt-3 px-3  align-items-center">
						<Col
							lg={6}
							xl={6}
							className=" pe-md-5 pe-lg-0  pe-0 position-relative  "
						>
							<h1 className="pt-3 mb-3 display-4 px-0" id="services-hero-title">
								{title}
							</h1>
							<p className="mb-3 fs-5">{description}</p>
							{/* <Button
								style={{ paddingBottom: "0.8rem" }}
								className="mt-3 me-md-3 w-100 w-md-auto fs-5 btn-black btn-hero btn-hero--animated cta-btn"
								variant="secondary"
								href="https://4053cb6e-4dcd-4e23-8761-e375f9fc1afb.scoreapp.com/?sa_target=_top"
								target="_blank"
								rel="noreferrer"
							>
								Your free online HR Audit
							</Button> */}
							<Button
								style={{ paddingBottom: "0.8rem" }}
								className="mt-3 me-md-3  w-100 w-md-auto fs-5 btn-black btn-hero btn-hero--animated cta-btn"
								variant="secondary"
								target="_blank"
								rel="noreferrer"
								href="https://calendly.com/gemma-baxter-hive-hr-solutions/free-30-minute-discovery-meeting"
							>
								Book a call
							</Button>

							<Button
								style={{ paddingBottom: "0.8rem" }}
								className="mt-3   w-100 w-md-auto fs-5 btn-black btn-hero btn-hero--animated  cta-btn"
								variant="secondary"
								as={Link}
								to="/contact-us"
							>
								Speak to our team
							</Button>

							{/* <div className=" position-absolute  top-100 pt-5 ">
								<GatsbyImage
									style={{ maxWidth: "10rem" }}
									image={badge}
									alt="Advocate of the year"
								/>
							</div> */}
						</Col>
						<Col lg={6} xl={6} className="d-none d-lg-block ">
							<BgImage
								className="position-relative  "
								image={image}
								alt={imgAlt}
								style={{ height: "50vh" }}
							>
								{" "}
								<div
									className=" position-relative start--10 bottom--90 mw-xl-60 mw-lg-70   bg-white p-4"
									style={{
										boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
										borderRadius: "10px",
									}}
								>
									<Row>
										<Col
											className={` d-flex justify-content-center pb-4 align-items-center 
					}`}
										>
											<GatsbyImage
												style={{ maxWidth: "5rem" }}
												image={
													review.Reviewinfo.image.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={review.Reviewinfo.image.altText}
											></GatsbyImage>
										</Col>
									</Row>
									<Row>
										<Col className="pe-0" xs={9}>
											<p className=" fw-bold pb-0 mb-0 pe-0 ">
												{review.Reviewinfo.companyName}
											</p>
										</Col>
										<Col xs={3}>
											<div className="d-flex align-items-center justify-content-end w-100">
												<p className="d-inline-block  pb-0 mb-0">
													{review.Reviewinfo.rating}
												</p>
												<SVGStarNormal
													className="d-inline-block ms-1"
													style={{ width: "15px", height: "15px" }}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col className="pt-3 ">
											<p className="d-inline-block mx-auto">
												{`"`}
												{review.Reviewinfo.review}
												{`"`}
											</p>
										</Col>
									</Row>
								</div>
							</BgImage>
						</Col>
					</Row>
					<Row className="d-lg-none pb-5 pt-5">
						<Col>
							<GatsbyImage
								className="position-relative  "
								image={image}
								alt={imgAlt}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</SSRProvider>
	);
};

export default Hero;
