import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import ReviewCard from "./review-card";
import { Button, Row } from "react-bootstrap";
import { Link, graphql, useStaticQuery } from "gatsby";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const WhatPeopleSayCarousel = () => {
	const [currentCardIndex, setCurrentCardIndex] = useState(0);
	const currentCardRef = useRef(null);
	const scrollContainerRef = useRef(null);
	const [readMore, setReadMore] = useState({ title: "" });

	let data = useStaticQuery(
		graphql`
			query {
				allWpReview(
					sort: { fields: dateGmt, order: DESC }
					limit: 10
					filter: {
						Reviewinfo: { onHomePage: { ne: true } }
						title: { ne: "Voluntary Services Lewisham Home Page" }
					}
				) {
					nodes {
						title
						Reviewinfo {
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							name
							rating
							review
							onHomePage
						}
						dateGmt
					}
				}
			}
		`
	);

	//   useEffect(() => {
	//     const cardSlideInterval = setInterval(() => {
	//       if (currentCardRef.current) {
	//         scrollContainerRef.current.scrollTo({
	//           left: currentCardRef.current.offsetLeft,
	//           behavior: "smooth",
	//         });
	//       }

	//       if (currentCardIndex >= data?.allWpReview?.nodes.length) {
	//         scrollContainerRef.current.scrollTo({
	//           left: currentCardRef.current.offsetLeft,
	//           behavior: "smooth",
	//         });
	//         setCurrentCardIndex(0);
	//       } else {
	//         setCurrentCardIndex(currentCardIndex + 1);
	//       }
	//     }, 3000);

	//     return () => {
	//       clearInterval(cardSlideInterval);
	//     };
	//   }, [currentCardIndex]);

	const handleSetReadMore = (reviewTitle) =>
		setReadMore((prevState) => ({
			title: prevState.title === reviewTitle ? "" : reviewTitle,
		}));

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<>
			<Container className="py-4 text-center">
				<Carousel
					responsive={responsive}
					autoPlay={true}
					autoPlaySpeed={3000}
					infinite={true}
				>
					{data?.allWpReview?.nodes.map((review, i) => {
						return (
							<div
								key={i}
								ref={currentCardIndex === i ? currentCardRef : null}
								className={`px-0 px-md-2 d-flex`}
							>
								<ReviewCard
									noShadow={true}
									currentReviewName={review.Reviewinfo.name}
									//   yellow={i % 2 === 0 ? true : false}
									yellow={true}
									review={review}
									readMore={readMore}
									onSetReadMore={handleSetReadMore}
								/>
							</div>
						);
					})}
				</Carousel>
				<Button
					style={{ paddingBottom: "0.8rem" }}
					className="mt-3  w-100 w-md-auto fs-5 btn-black btn-hero btn-hero--animated cta-btn"
					variant="secondary"
					as={Link}
					to="/testimonials"
				>
					More testimonials
				</Button>
			</Container>
			{/* <Container fluid className="pt-3 ml-3">
        <Row className="review-card-shadow">
          <div
            ref={scrollContainerRef}
            className="overflow-x-hidden d-flex flex-row column-gap-2"
            style={{ overflowX: "scroll" }}
          >
            {data?.allWpReview?.nodes.map((review, i) => {
              return (
                <div
                  key={i}
                  ref={currentCardIndex === i ? currentCardRef : null}
                  className={`col col-xs-3 col-md-2.5 col-xl-3 py-xl-3 d-flex`}
                >
                  <ReviewCard
                    currentReviewName={review.Reviewinfo.name}
                    yellow={i % 2 === 0 ? true : false}
                    review={review}
                    readMore={readMore}
                    onSetReadMore={handleSetReadMore}
                  />
                </div>
              );
            })}
          </div>
        </Row>
      </Container> */}
		</>
	);
};

export default WhatPeopleSayCarousel;
