import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				allWpReview {
					totalCount
				}

				schemaLogo: wpMediaItem(title: { eq: "Logo" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: TRACED_SVG
							)
						}
					}
				}
			}
		`
	);

	const reviewCount = data.allWpReview?.totalCount;
	const schemaLogo = data.schemaLogo.localFile.publicURL;
	const schemaImg = data.heroImg.localFile.publicURL;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		"@id": "https://id.hivehrsolutions.co.uk",
		name: "Hive HR Solutions",
		legalName: "Hive HR Solutions Ltd",
		description:
			"The HR consultancy company that provides the answers to all your HR support problems.",
		url: "https://www.hivehrsolutions.co.uk",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Horsham",
				sameAs: "https://en.wikipedia.org/wiki/Horsham",
			},
		],
		email: "ask@hivehrsolutions.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "1",
		location: {
			"@type": "City",
			name: "Horsham",
			sameAs: "https://en.wikipedia.org/wiki/Horsham",
		},
		foundingLocation: {
			"@type": "City",
			name: "Horsham",
			sameAs: "https://en.wikipedia.org/wiki/Horsham",
		},
		slogan:
			"You don’t build a business – you build people – then the people build your business",
		telephone: "+441403627630",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Horsham",
			addressRegion: "West Sussex",
			postalCode: "RH12 4TB",
			streetAddress: "Roffey Park Institute, Forest Rd",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "51.0649891",
			longitude: "-0.3642804",
		},
		hasMap: "https://g.page/hive-hr-solutions",
		openingHours: "Mo,Tu,We,Th,Fr 09:00-17:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "09:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "09:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Gemma Baxter",
			id: "https://hivehrsolutions.co.uk/who-we-are",
			url: "https://hivehrsolutions.co.uk/who-we-are",
			description:
				"Owner and founder of Hive HR Solutions, Gemma is down to earth and approachable. With 20 years of experience up to HR Director level she has worked in the public and private sector, as well as for charities.",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "5",
			reviewCount: `${reviewCount}`,
			url: "https://www.hivehrsolutions.co.uk",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.hivehrsolutions.co.uk/contact-us",
			url: "https://www.hivehrsolutions.co.uk/contact-us",
			image: `${schemaImg}`,
			name: "Hive HR Solutions",
			description:
				"Our Hive HR Solutions business draws on over 14 years of HR experience in various organisations including Kenwood, Southern Co-operative, Lebara, Aldermore Bank & Glyndebourne Opera House. We can  provide your business with a robust and reliable HR service that takes the stress out of the most complex situations, leaving you free to run your business.",
			telephone: "+441403627630",
			contactType: "Form",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Horsham",
					sameAs: "https://en.wikipedia.org/wiki/Horsham",
				},
			],
		},
		logo: `${schemaLogo}`,
		image: `${schemaImg}`,
		sameAs: [
			"https://www.facebook.com/hivehrsolutions/",
			"https://twitter.com/HIVE_HRSOLUTION",
			"https://www.linkedin.com/company-beta/11227150/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
