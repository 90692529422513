import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReviewCard from "./review-card";
import { graphql, useStaticQuery } from "gatsby";
import SVGPolygon from "./SVG/polygon";

const WhatPeopleSay = () => {
	const data = useStaticQuery(
		graphql`
			query {
				allWpReview(
					sort: { fields: dateGmt, order: ASC }
					limit: 2
					filter: { Reviewinfo: { onHomePage: { eq: true } } }
				) {
					nodes {
						title
						Reviewinfo {
							image {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							name
							rating
							review
							onHomePage
						}
						dateGmt
					}
				}
			}
		`
	);
	return (
		<Container className="pt-3">
			<Row className=" pt-3 px-3 align-items-center">
				<Col xl={4} xs={12} className="pe-lg-5 pb-4 px-xl-0 ps-0">
					<div className="position-relative">
						<SVGPolygon
							className="position-absolute d-none d-lg-block"
							style={{ zIndex: -1, left: "-1rem", top: "-1.2rem" }}
						/>
						<h2 className="pt-3 text-center text-xl-start mb-3 fs-1">
							What people say about Hive HR Solutions
						</h2>
					</div>
				</Col>

				<Col className="pt-xl-4" md={6} xl={4}>
					<ReviewCard review={data.allWpReview?.nodes[0]} />
				</Col>
				<Col className="pb-xl-6" md={6} xl={4}>
					<ReviewCard
						yellow
						bgTrue
						bgColor="bg-primary"
						review={data.allWpReview?.nodes[1]}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default WhatPeopleSay;
