import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Banner = ({ text, bgColor }) => {
	return (
		<section
			style={{ zIndex: 3 }}
			className={`position-relative bg-${bgColor} py-5`}
		>
			<Container>
				<Row className="align-items-center ">
					<Col className="mb-4 mb-lg-0 text-center">
						<p className="my-auto  fs-2 text-secondary">
							<span className="text-primary">“</span>
							{text}
							<span className="text-primary">”</span>
						</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Banner;
