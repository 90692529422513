import * as React from "react";

const SVGRestructures = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M65.0124 52.7375H56.2624V50.2375H53.7624V52.7375H44.3374V57.7375H46.8374V55.2375H53.7624V57.7375H56.2624V55.2375H63.1374V57.7375H65.6374V52.7375H65.0124Z"
			fill="black"
		/>
		<path
			d="M55.0125 47.7625C56.7383 47.7625 58.1375 46.3633 58.1375 44.6375C58.1375 42.9116 56.7383 41.5125 55.0125 41.5125C53.2866 41.5125 51.8875 42.9116 51.8875 44.6375C51.8875 46.3633 53.2866 47.7625 55.0125 47.7625Z"
			fill="black"
		/>
		<path
			d="M45.5874 66.4875C47.3133 66.4875 48.7124 65.0884 48.7124 63.3625C48.7124 61.6367 47.3133 60.2375 45.5874 60.2375C43.8615 60.2375 42.4624 61.6367 42.4624 63.3625C42.4624 65.0884 43.8615 66.4875 45.5874 66.4875Z"
			fill="black"
		/>
		<path
			d="M55.0125 66.4875C56.7383 66.4875 58.1375 65.0884 58.1375 63.3625C58.1375 61.6367 56.7383 60.2375 55.0125 60.2375C53.2866 60.2375 51.8875 61.6367 51.8875 63.3625C51.8875 65.0884 53.2866 66.4875 55.0125 66.4875Z"
			fill="black"
		/>
		<path
			d="M64.3875 66.4875C66.1133 66.4875 67.5125 65.0884 67.5125 63.3625C67.5125 61.6367 66.1133 60.2375 64.3875 60.2375C62.6616 60.2375 61.2625 61.6367 61.2625 63.3625C61.2625 65.0884 62.6616 66.4875 64.3875 66.4875Z"
			fill="black"
		/>
	</svg>
);

export default SVGRestructures;
