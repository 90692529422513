import * as React from "react";

const SVGHh = (props) => (
	<svg
		width={1266}
		height={737}
		viewBox="0 0 1266 737"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M798.444 -867.193C802.017 -867.193 805.318 -865.287 807.105 -862.193L1264.14 -70.5887C1265.92 -67.4947 1265.92 -63.6827 1264.14 -60.5887L807.105 731.016C805.318 734.11 802.017 736.016 798.444 736.016L-115.622 736.016C-119.195 736.016 -122.496 734.11 -124.282 731.016L-581.315 -60.5887C-583.102 -63.6826 -583.102 -67.4946 -581.315 -70.5887L-124.282 -862.193C-122.496 -865.287 -119.195 -867.193 -115.622 -867.193L798.444 -867.193Z"
			fill="url(#paint0_linear_58_3297)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_58_3297"
				x1={804.218}
				y1={-867.193}
				x2={-346.988}
				y2={647.174}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9AD11" />
				<stop offset={1} stopColor="#FDC020" />
			</linearGradient>
		</defs>
	</svg>
);

export default SVGHh;
