import * as React from "react";

const SVGFolder = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M41.1494 45.3493L41.0741 43.7501C41.0741 42.775 41.4705 41.8399 42.1761 41.1504C42.8818 40.4609 43.8388 40.0736 44.8367 40.0736H51.7447C52.7425 40.0738 53.6994 40.4613 54.4048 41.1508L55.9625 42.6729C56.6679 43.3624 57.6248 43.7499 58.6226 43.7501H66.1138C66.6367 43.75 67.1538 43.8565 67.6322 44.0626C68.1106 44.2687 68.5398 44.57 68.8924 44.9472C69.245 45.3244 69.5133 45.7693 69.6801 46.2535C69.8469 46.7376 69.9086 47.2505 69.8613 47.7593L68.663 60.6269C68.578 61.5404 68.1467 62.3898 67.4537 63.0085C66.7607 63.6271 65.856 63.9703 64.9173 63.9707H45.45C44.5113 63.9703 43.6066 63.6271 42.9136 63.0085C42.2206 62.3898 41.7893 61.5404 41.7044 60.6269L40.506 47.7593C40.4258 46.9078 40.6535 46.0557 41.1494 45.3512V45.3493ZM44.2535 45.5883C43.9921 45.5883 43.7337 45.6415 43.4945 45.7445C43.2554 45.8475 43.0409 45.998 42.8646 46.1866C42.6883 46.3751 42.5541 46.5974 42.4706 46.8394C42.3872 47.0814 42.3562 47.3377 42.3797 47.592L43.5781 60.4596C43.6203 60.9164 43.8358 61.3412 44.1821 61.6507C44.5284 61.9602 44.9806 62.132 45.45 62.1324H64.9173C65.3867 62.132 65.8389 61.9602 66.1852 61.6507C66.5315 61.3412 66.747 60.9164 66.7892 60.4596L67.9876 47.592C68.0111 47.3377 67.9801 47.0814 67.8967 46.8394C67.8132 46.5974 67.679 46.3751 67.5027 46.1866C67.3265 45.998 67.1119 45.8475 66.8728 45.7445C66.6336 45.6415 66.3752 45.5883 66.1138 45.5883H44.2535ZM53.0766 42.4504C52.9018 42.2795 52.6941 42.144 52.4656 42.0515C52.237 41.9591 51.992 41.9117 51.7447 41.9118H44.8367C44.3439 41.9118 43.8707 42.1006 43.5189 42.4378C43.1671 42.7751 42.9648 43.2337 42.9554 43.7152L42.9667 43.9707C43.3693 43.8273 43.802 43.7501 44.2535 43.7501H54.4048L53.0748 42.4504H53.0766Z"
			fill="black"
		/>
	</svg>
);

export default SVGFolder;
