import * as React from "react";

const SVGHPBgPoly2 = (props) => (
	<svg
		width={550}
		height={898}
		viewBox="0 0 550 898"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M769.331 0.866791C772.903 0.866782 776.205 2.77279 777.991 5.86679L1031.08 444.237C1032.87 447.331 1032.87 451.143 1031.08 454.237L777.991 892.608C776.205 895.702 772.903 897.608 769.331 897.608L263.144 897.608C259.571 897.608 256.27 895.702 254.484 892.608L1.39047 454.237C-0.395837 451.143 -0.395838 447.331 1.39048 444.237L254.484 5.86679C256.27 2.77277 259.571 0.866785 263.144 0.866794L769.331 0.866791Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGHPBgPoly2;
