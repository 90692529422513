import * as React from "react";

const SVGSoftware = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M60.5 64.9C59.1366 64.241 57.9658 63.2424 57.1 62H46.75V47H69.25V49.825C70.262 50.5363 71.1136 51.4518 71.75 52.5125V47C71.75 46.337 71.4866 45.7011 71.0178 45.2322C70.5489 44.7634 69.913 44.5 69.25 44.5H46.75C46.087 44.5 45.4511 44.7634 44.9822 45.2322C44.5134 45.7011 44.25 46.337 44.25 47V62C44.25 62.663 44.5134 63.2989 44.9822 63.7678C45.4511 64.2366 46.087 64.5 46.75 64.5H55.5V67H53V69.5H63V67H60.5V64.9Z"
			fill="black"
		/>
		<path
			d="M64.25 49.5C62.7666 49.5 61.3166 49.9399 60.0832 50.764C58.8499 51.5881 57.8886 52.7594 57.3209 54.1299C56.7532 55.5003 56.6047 57.0083 56.8941 58.4632C57.1835 59.918 57.8978 61.2544 58.9467 62.3033C59.9956 63.3522 61.332 64.0665 62.7868 64.3559C64.2417 64.6453 65.7497 64.4968 67.1201 63.9291C68.4906 63.3614 69.6619 62.4001 70.486 61.1668C71.3101 59.9334 71.75 58.4834 71.75 57C71.75 55.0109 70.9598 53.1032 69.5533 51.6967C68.1468 50.2902 66.2391 49.5 64.25 49.5ZM64.25 58.875C63.8792 58.875 63.5167 58.765 63.2083 58.559C62.9 58.353 62.6596 58.0601 62.5177 57.7175C62.3758 57.3749 62.3387 56.9979 62.411 56.6342C62.4834 56.2705 62.662 55.9364 62.9242 55.6742C63.1864 55.412 63.5205 55.2334 63.8842 55.161C64.2479 55.0887 64.6249 55.1258 64.9675 55.2677C65.3101 55.4096 65.603 55.65 65.809 55.9583C66.015 56.2666 66.125 56.6292 66.125 57C66.125 57.4973 65.9275 57.9742 65.5758 58.3258C65.2242 58.6775 64.7473 58.875 64.25 58.875Z"
			fill="black"
		/>
	</svg>
);

export default SVGSoftware;
