import { useStaticQuery, graphql, Link } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Hero from "../components/hero";
import ServiceCard from "../components/service-card";
import Banner from "../components/banner";
import { CookieBanner } from "../components/cookie";
import Footer from "../components/footer";
import BeeBanner from "../components/bee-banner";
import WhatPeopleSay from "../components/what-people-say";
import PhotoLeft from "../components/photo-left";
import PhotoRight from "../components/photo-right";
import CtaBanner from "../components/cta-banner";
import SVGLargeBgBee from "../components/SVG/large-bg-bee.js";
import SVGFBHp1 from "../components/SVG/flying-bee-hp-1";
import SVGFBHp2 from "../components/SVG/flying-bee-hp-2";
import SVGFBHp3 from "../components/SVG/flying-bee-hp-3";
import SVGRecruitment from "../components/SVG/recruitment";
import SVGRestructures from "../components/SVG/restructures";
import SVGErHp from "../components/SVG/employee-relation-hp";
import SVGDocumentation from "../components/SVG/documentation";
import SVGSoftware from "../components/SVG/software";
import SVGFolder from "../components/SVG/folder-svg";
import SVGHPBgPoly1 from "../components/SVG/homepage-bg-poly-1";
import SVGHPBgPoly2 from "../components/SVG/homepage-bg-poly-2";
import SVGHPBgPoly3 from "../components/SVG/homepage-bg-poly-3";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import WhatPeopleSayCarousel from "../components/what-people-say-carousel";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tailorMadeImg: wpMediaItem(title: { eq: "Tailor-Made-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			badgeImg: wpMediaItem(title: { eq: "Advocate of the year" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			tailorMadeImg: wpMediaItem(title: { eq: "Tailor-Made-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			efficientUseImg: wpMediaItem(title: { eq: "_DSC4169" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ourTeamImg: wpMediaItem(title: { eq: "_DSC4074" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			personalServiceImg: wpMediaItem(title: { eq: "Personal-Service-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			proactiveImg: wpMediaItem(title: { eq: "Proactive-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			bespokeImg: wpMediaItem(title: { eq: "_DSC4135" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpReview(
				limit: 1
				filter: { Reviewinfo: { onHeroImage: { eq: true } } }
			) {
				nodes {
					title
					Reviewinfo {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: TRACED_SVG
									)
								}
							}
						}
						companyName
						name
						rating
						review
						onHeroImage
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const badgeImg = data.badgeImg?.localFile.childImageSharp.gatsbyImageData;
	const bespokeImg = data.bespokeImg?.localFile.childImageSharp.gatsbyImageData;
	const tailorMadeImg =
		data.tailorMadeImg?.localFile.childImageSharp.gatsbyImageData;
	const efficientUseImg =
		data.efficientUseImg?.localFile.childImageSharp.gatsbyImageData;
	const ourTeamImg = data.ourTeamImg?.localFile.childImageSharp.gatsbyImageData;
	const personalServiceImg =
		data.personalServiceImg?.localFile.childImageSharp.gatsbyImageData;
	const proactiveImg =
		data.proactiveImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};
	if (typeof window !== "undefined") {
		return (
			<div style={{ overflow: "hidden" }}>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<noscript>
					<style type="text/css">
						[data-sal|='fade']{" "}
						{{
							opacity: 1,
						}}
						[data-sal|='slide'], [data-sal|='zoom']{" "}
						{{
							opacity: 1,
							transform: "none",
						}}
						[data-sal|='flip']{" "}
						{{
							transform: "none",
						}}
					</style>
				</noscript>

				<Helmet></Helmet>
				<LocalBusinessSchema />
				<CookieBanner />
				<GatsbySeo
					title="Outsourced HR Consultancy based in Sussex | Hive HR Solutions"
					description="The HR consultancy company that provides the answers to all your HR support problems."
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}`,
						title: "HR Consultants based in Horsham, Sussex",
						description:
							"The HR consultancy company that provides the answers to all your HR support problems.",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>
				<Hero
					badge={badgeImg}
					review={data.allWpReview?.nodes[0]}
					title={
						<span>
							Welcome to
							<br /> Hive HR Solutions
						</span>
					}
					description="The HR consultancy company that provides tailored answers to all your HR support problems."
					img={heroImg}
					imgAlt={data.heroImg?.altText}
				/>
				<section style={{ zIndex: 99 }} className="pt-lg-5 position-relative">
					<WhatPeopleSayCarousel />
				</section>
				<section id="introduction" className="pt-xl-10 pt-9  position-relative">
					<SVGFBHp1 className="position-absolute end-0 top--10 d-none d-lg-block" />
					<SVGLargeBgBee
						style={{ height: "90rem", width: "90rem", top: "-12rem" }}
						className="position-absolute start-50 translate-middle-x d-none d-lg-block "
					/>
					<Container className="py-lg-6 py-4 pt-md-0">
						<Row>
							<ServiceCard
								icon={<SVGFolder width={100} />}
								header="HR Consultancy"
								text="HR Strategic consultancy with one off projects or a retained arrangement where you can outsource all your HR needs."
							/>
							<ServiceCard
								icon={<SVGRecruitment width={100} />}
								header="Recruitment and Onboarding"
								text="Assessing current recruitment processes and onboarding experiences to identify improvements and recommend systems and automation solutions."
							/>
							<ServiceCard
								icon={<SVGRestructures width={100} />}
								header="Management training and development"
								text="Developing your managers by providing training to give them the skills and confidence to manage their teams well."
							/>
							<ServiceCard
								icon={<SVGErHp width={100} />}
								header="EMPLOYEE RELATIONS"
								text="We can assist with: development of a robust disciplinary policy, dealing with individual disciplinary & grievance cases, carrying out investigations, arranging and conducting disciplinary and grievance hearings"
							/>
							<ServiceCard
								icon={<SVGDocumentation width={100} />}
								header="Tailored Policies & Procedures"
								text="Develop tailored policies and procedures that are lawful and relevant to your organisation."
							/>
							<ServiceCard
								icon={<SVGSoftware width={100} />}
								header="HR SOFTWARE"
								text="We are partnered with breathe hr software to offer companies an affordable and user friendly system. The system offers employees self service to manage their own data and holiday requests.  A place to store employee files and company documents."
							/>
						</Row>
					</Container>
				</section>
				<section>
					<Banner
						bgColor="banner"
						text={
							<span>
								You are only as good as the <br /> people you hire
							</span>
						}
					/>
				</section>
				<section className="pt-lg-5">
					<PhotoLeft
						idLeft="Tailor-made-Left"
						idRight="Tailor-made-Right"
						height="25rem"
						width="37rem"
						img={tailorMadeImg}
						imgAlt={data.tailorMadeImg?.altText}
						heading="Tailor-made HR support"
						text={
							<span>
								<p className="fw-bold fs-5">
									Save your time and improve employee engagement
								</p>
								<p>
									Do you run a business in Horsham,{" "}
									<Link
										to="/locations/hr-brighton"
										className="seo-link text-decoration-none "
									>
										Brighton
									</Link>
									,{" "}
									<Link
										className="seo-link text-decoration-none"
										to="/locations/hr-worthing"
									>
										Worthing
									</Link>{" "}
									or anywhere in the South East of England and want to work with
									an{" "}
									<Link className="black-link" to="/locations/hr-brighton">
										HR consultancy
									</Link>{" "}
									company that gets a real buzz out of helping growing companies
									find the right staff and retain them?
								</p>
								<p>
									At Hive HR Solutions we're here to support you on your journey
									to grow your business, from writing job specs to delivering
									management training courses, we can help you to get the best
									results from your team leaving you free to focus on what you
									do best.
								</p>
							</span>
						}
					/>
				</section>

				<section className="pt-xl-10  pb-md-5 pb-xl-0 position-relative">
					<SVGFBHp2
						style={{ zIndex: -2 }}
						className="position-absolute end-0 top--30 d-none d-xl-block"
					/>
					<SVGHPBgPoly1
						style={{ zIndex: -3 }}
						className="position-absolute top--50 d-none d-lg-block"
					/>
					<PhotoRight
						height="25rem"
						width="37rem"
						img={bespokeImg}
						imgAlt={data.bespokeImg?.altText}
						heading="Add value with a bespoke human resources support service"
						text={
							<span>
								<p>
									Do you want to add value to your company with personalised HR
									packages that suits your budget and requirements?
								</p>
								<p>
									Are you tired of trying to do everything yourself? Would you
									like{" "}
									<Link className="black-link" to="/locations/hr-brighton">
										HR support
									</Link>{" "}
									from a company based in Sussex that works with clients across
									the south-east of England that can identify any problems
									before they happen and avoid unwanted disputes?
								</p>
								<p>
									At Hive HR Solutions we understand that in order to have
									satisfied customers you need to ensure your staff are happy,
									which is why we provide a comprehensive suite of human
									resources management services for small and medium-sized
									businesses.
								</p>
							</span>
						}
					/>
				</section>

				<section>
					<CtaBanner
						headline={
							<span>
								Call now to find out more about our
								<br />
								<Link className="black-link" to="/locations/hr-worthing">
									HR support
								</Link>{" "}
								services
							</span>
						}
						btnTxt="Contact us"
						btnLink="/contact-us"
					/>
				</section>

				{/* <section>
          <WhatPeopleSay />
        </section> */}

				<section>
					{" "}
					<PhotoLeft
						idLeft="Efficient-Left"
						idRight="Efficient-Right"
						height="25rem"
						width="37rem"
						img={efficientUseImg}
						imgAlt={data.efficientUseImg?.altText}
						heading="Efficient use of your time through regular HR support meetings "
						text={
							<span>
								<p>
									How would you like the reassurance of knowing that your human
									resources needs are being tackled once a week in one hit?
									Would you like your HR issues to be pre-empted and addressed
									by someone who understands your company's HR needs?
								</p>
								<p>
									As a regular client you can benefit from weekly meetings - or
									at a frequency that best suits you - to discuss your current
									and future HR needs. We will organise the meeting, from
									writing the agenda to identifying the actions. All you have to
									do is show up, we'll take care of the rest.
								</p>
							</span>
						}
					/>
				</section>
				<section className="py-xl-10 position-relative">
					<SVGFBHp3
						style={{ zIndex: -2 }}
						className="position-absolute bottom--20 start-20 d-none d-xl-block "
					/>
					<SVGHPBgPoly2
						style={{ zIndex: -2 }}
						className="position-absolute d-none d-lg-block end-0 top-50 translate-middle-y"
					/>
					<PhotoRight
						height="25rem"
						width="38rem"
						cta="Read more"
						ctaUrl="/who-we-are"
						img={ourTeamImg}
						imgAlt={data.ourTeamImg?.altText}
						heading="Our team"
						text={
							<span>
								<p>
									Hive HR Solutions was founded in 2017 as a small company with
									big ambitions: to shake up the HR consultancy world by
									providing a personalised human resources support service.
								</p>
							</span>
						}
						secondaryCta="Careers"
						secondaryCtaUrl="https://hivehrsolutionsltd.livevacancies.co.uk/#/"
					/>
				</section>
				<section className="pt-xl-4">
					{" "}
					<PhotoLeft
						idLeft="Personal-Left"
						idRight="Personal-Right"
						height="25rem"
						width="38rem"
						img={personalServiceImg}
						imgAlt={data.personalServiceImg?.altText}
						heading="Personal service from a company that really cares"
						text={
							<span>
								<p>
									Your company is as important to us as it is to you. That's why
									you'll always hear the friendly voice of one of our team
									members at the end of the phone who knows your company inside
									out, the direction you want to take it in and who is committed
									to helping you achieve your HR goals.
								</p>
								<p>
									Your peace of mind matters to us, which is why our team of
									experienced, professional human resources management experts
									are here for you and are ready to take the sting out of your
									HR issues wherever you are located in Horsham,{" "}
									<Link
										to="/locations/hr-brighton"
										className="seo-link text-decoration-none "
									>
										Brighton
									</Link>
									,{" "}
									<Link
										className="seo-link text-decoration-none"
										to="/locations/hr-worthing"
									>
										Worthing
									</Link>{" "}
									or anywhere in the South East of England.
								</p>
							</span>
						}
					/>
				</section>
				<section className="pt-xl-4">
					<PhotoRight
						fw={true}
						height="27rem"
						width="33rem"
						img={proactiveImg}
						imgAlt={data.proactiveImg.altText}
						heading="Proactive rather than reactive HR support "
						text={
							<span>
								<p>
									At Hive HR Solutions in Horsham, West Sussex, we're passionate
									about taking a proactive approach to solving your company's
									human resources problems.
								</p>
								<p>
									By looking ahead we can save you money, stress and time by
									anticipating any future HR problems, identifying any HR
									compliance gaps and resolving them by crafting an action plan
									that evolves at the same pace as your business.
								</p>
								<p>
									If you want to avoid future HR issues, costs and possibly even
									litigation, invest in your company's HR now so you can avoid
									paying a heftier price later.
								</p>
								<p>
									You've got this far on your own, now let us help you take your
									business to the next level…
								</p>
							</span>
						}
					/>
				</section>
				<section className="position-relative">
					<SVGHPBgPoly3
						style={{ zIndex: -2 }}
						className="position-absolute top--50 d-none d-lg-block"
					/>
					<BeeBanner
						headline="Ready to speak to us?"
						text={
							<span>
								Call now to find out more about <br /> our HR services.
							</span>
						}
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
				<Footer />
			</div>
		);
	} else {
		return <div style={{ overflow: "hidden" }}></div>;
	}
};

export default IndexPage;
