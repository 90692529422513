import * as React from "react";

const SVGHPBgPoly3 = (props) => (
	<svg
		width={348}
		height={877}
		viewBox="0 0 348 877"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M84.3305 0.866791C87.9031 0.866782 91.2044 2.77279 92.9908 5.86679L346.084 444.237C347.87 447.331 347.87 451.143 346.084 454.237L92.9908 892.608C91.2044 895.702 87.9032 897.608 84.3305 897.608L-421.856 897.608C-425.429 897.608 -428.73 895.702 -430.516 892.608L-683.61 454.237C-685.396 451.143 -685.396 447.331 -683.61 444.237L-430.516 5.86679C-428.73 2.77277 -425.429 0.866785 -421.856 0.866794L84.3305 0.866791Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGHPBgPoly3;
