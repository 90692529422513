import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";

const CtaBanner = ({ headline, btnTxt, btnLink, gradient }) => {
	const ctaLink = btnLink || "/contact-us";

	return (
		<section
			className={` position-relative ${gradient ? "" : "bg-banner"} py-5`}
		>
			<div
				className={`h-100 w-100 top-0 position-absolute ${
					gradient ? "" : "d-none"
				} `}
				style={{
					background:
						"linear-gradient(269.28deg, #fbba00 3.32%, #FFD337 101.94%)",
					zIndex: -1,
				}}
			></div>
			<Container>
				<Row className="align-items-lg-center text-center">
					<Col className="mb-4 mb-md-0 ">
						<h2 className="mb-4 ">{headline}</h2>

						<Button
							as={Link}
							variant="secondary"
							size="lg"
							className="btn-black  w-100 w-md-auto cta-btn"
							to={ctaLink}
							id="cta-banner-button"
						>
							{btnTxt}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CtaBanner;
