import * as React from "react";

const SVGErHp = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.1345 19.6078C72.7019 19.6078 75.985 21.46 77.7687 24.4787L92.0292 48.6136C93.8399 51.678 93.8301 55.4778 92.0036 58.569L77.8446 82.532C76.0181 85.6233 72.6553 87.5314 69.0339 87.5314L40.513 87.5314C36.9456 87.5314 33.6625 85.6793 31.8788 82.6605L17.6184 58.5257C15.8077 55.4612 15.8175 51.6615 17.644 48.5703L31.8029 24.6072C33.6294 21.516 36.9923 19.6078 40.6136 19.6078L69.1345 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M55 51.98C57.6289 51.98 59.76 49.8758 59.76 47.28C59.76 44.6843 57.6289 42.58 55 42.58C52.3711 42.58 50.24 44.6843 50.24 47.28C50.24 49.8758 52.3711 51.98 55 51.98Z"
			fill="black"
		/>
		<path
			d="M47.78 47.75H48.26V47.32C48.2629 46.0696 48.6156 44.845 49.2784 43.7846C49.9411 42.7243 50.8873 41.8706 52.01 41.32C51.6776 40.5546 51.1441 39.8935 50.4662 39.4069C49.7883 38.9203 48.9912 38.6264 48.1597 38.5564C47.3281 38.4864 46.4932 38.6429 45.7434 39.0094C44.9937 39.3758 44.3572 39.9385 43.9016 40.6376C43.446 41.3367 43.1882 42.1462 43.1557 42.9801C43.1232 43.8139 43.3171 44.641 43.7169 45.3735C44.1167 46.106 44.7074 46.7165 45.4264 47.1402C46.1453 47.5639 46.9655 47.785 47.8 47.78L47.78 47.75Z"
			fill="black"
		/>
		<path
			d="M61.76 47.28V47.71H62.24C63.0634 47.7017 63.8693 47.4717 64.5729 47.044C65.2765 46.6163 65.8518 46.0069 66.2383 45.2798C66.6247 44.5527 66.808 43.7349 66.7689 42.9125C66.7297 42.09 66.4696 41.2933 66.0158 40.6062C65.562 39.9191 64.9314 39.3671 64.1903 39.0082C63.4493 38.6493 62.6252 38.4968 61.8048 38.5668C60.9843 38.6368 60.198 38.9267 59.5284 39.4059C58.8588 39.8851 58.3308 40.536 58 41.29C59.123 41.8384 60.0703 42.69 60.7347 43.7485C61.3992 44.807 61.7543 46.0302 61.76 47.28Z"
			fill="black"
		/>
		<path
			d="M59.29 52.45C61.2783 52.8436 63.2008 53.5169 65 54.45C65.2535 54.5893 65.4831 54.7682 65.68 54.98H71V51.56C71.001 51.4289 70.9661 51.2999 70.8992 51.1871C70.8322 51.0743 70.7357 50.982 70.62 50.92C68.0282 49.5643 65.145 48.8607 62.22 48.87H61.56C61.2135 50.2787 60.4164 51.5358 59.29 52.45Z"
			fill="black"
		/>
		<path
			d="M43.53 56.92C43.5282 56.4164 43.6642 55.9219 43.9233 55.49C44.1824 55.0581 44.5548 54.7054 45 54.47C46.7993 53.5369 48.7218 52.8636 50.71 52.47C49.5891 51.5635 48.7925 50.3178 48.44 48.92H47.78C44.855 48.9107 41.9719 49.6143 39.38 50.97C39.2644 51.032 39.1679 51.1243 39.1009 51.2371C39.0339 51.3499 38.999 51.4788 39 51.61V58H43.53V56.92Z"
			fill="black"
		/>
		<path d="M58.46 62.69H64.42V64.09H58.46V62.69Z" fill="black" />
		<path
			d="M69.81 57.26H62.94V56.26C62.94 55.9948 62.8347 55.7405 62.6472 55.5529C62.4596 55.3654 62.2053 55.26 61.94 55.26C61.6748 55.26 61.4205 55.3654 61.2329 55.5529C61.0454 55.7405 60.94 55.9948 60.94 56.26V57.26H59V54.43C57.6844 54.1538 56.3444 54.0097 55 54C51.8438 53.9869 48.7327 54.7492 45.94 56.22C45.8157 56.2846 45.7115 56.3822 45.6392 56.5022C45.5668 56.6222 45.5291 56.7599 45.53 56.9V62.51H52.64V68.6C52.64 68.8653 52.7454 69.1196 52.9329 69.3072C53.1205 69.4947 53.3748 69.6 53.64 69.6H69.81C70.0753 69.6 70.3296 69.4947 70.5172 69.3072C70.7047 69.1196 70.81 68.8653 70.81 68.6V58.26C70.81 57.9948 70.7047 57.7405 70.5172 57.5529C70.3296 57.3654 70.0753 57.26 69.81 57.26ZM68.81 67.62H54.64V59.26H60.94V60.17C60.94 60.4353 61.0454 60.6896 61.2329 60.8772C61.4205 61.0647 61.6748 61.17 61.94 61.17C62.2053 61.17 62.4596 61.0647 62.6472 60.8772C62.8347 60.6896 62.94 60.4353 62.94 60.17V59.26H68.81V67.62Z"
			fill="black"
		/>
	</svg>
);

export default SVGErHp;
