import * as React from "react";

const SVGHPBgPoly1 = (props) => (
	<svg
		width={517}
		height={898}
		viewBox="0 0 517 898"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M253.331 0.866791C256.903 0.866782 260.205 2.77279 261.991 5.86679L515.084 444.237C516.87 447.331 516.87 451.143 515.084 454.237L261.991 892.608C260.205 895.702 256.903 897.608 253.331 897.608L-252.856 897.608C-256.429 897.608 -259.73 895.702 -261.516 892.608L-514.61 454.237C-516.396 451.143 -516.396 447.331 -514.61 444.237L-261.516 5.86679C-259.73 2.77277 -256.429 0.866785 -252.856 0.866794L253.331 0.866791Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGHPBgPoly1;
